:root {
  .item-navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 16px;
    font-size: 12px;
    background: transparent;
    padding: 8px;
    color: #828282;
    cursor: pointer;
    border-radius: 3px;

    &:hover,
    &:active {
      background: #2a2c33;
      color: #fff;
    }
  }
  .info-type {
    color: #2f80ed;
    font-size: 12px;
    line-height: 14px;
  }
  .item-active {
    background: #2a2c33;
    color: #fff;
  }

  // navbar
  .navbar-tradingview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 12px;
    box-sizing: border-box;
    background: rgba($color: #20222e, $alpha: 0.6);
    border-radius: 10px;

    &__left,
    &__right {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  .parent-position {
    position: relative;
  }
  .chart-stype-popup {
    position: absolute;
    top: 25px;
    right: 0;
  }
  .group-interval-parent {
    position: absolute;
    top: 25px;
  }
  .group-interval {
    padding: 16px;
    width: 227px;
    height: 166px;
    text-align: center;
    background-color: rgba($color: #20222e, $alpha: 0.6);
    border-radius: 10px;
  }
  .item-navbar {
    position: relative;
  }
  .group-interval-line {
    display: flex;
    justify-content: space-between;
  }
  .group-interval-line div {
    width: 42px;
    height: 22px;
    border-radius: 5px;
    background-color: #828282;
    cursor: pointer;
    color: #fff;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
  }
  .group-interval-line div:hover {
    background: #1a88c9;
    color: #fff;
  }
  .group-interval-line-config {
    margin: 15px 0px;
  }
  .group-interval-header {
    display: flex;
    justify-content: space-between;
  }
  .group-interval-header div {
    font-size: 12px;
    font-family: "Roboto";
  }
  .group-interval-header div:nth-child(1) {
    opacity: 0.5;
  }
  .group-interval-header div:nth-child(2) {
    // color: var(--color-warning);
    cursor: pointer;
  }
  .btn-edit {
    color: #1a88c9;
    cursor: pointer;
  }
  .chart-type-parent {
    background-color: rgba($color: #20222e, $alpha: 1);
    width: 150px;
    border-radius: 10px;
  }
  .chart-type-child {
    font-size: 12px;
    font-family: "Roboto";
    margin: 8px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    img {
      margin-right: 8px;
    }
  }
  .chart-type-child:hover {
    background: #1a88c9;
  }
  .chart-type-child img {
    width: 12%;
  }
  .config-background {
    background-color: #1a88c9;
  }
  .full-screen-img {
    width: 13px;
    height: 13px;
  }
  .interval-btn-active {
    background-color: #1a88c9 !important;
    position: relative;
  }
  .hidden-navbar-left {
    visibility: hidden;
  }
  .tick-color-select-interval {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 35px;
    left: 50px;
  }
  .interval-button {
    position: relative;
    .interval-img {
      position: absolute;
      top: -6px;
      left: 35px;
    }
  }
}
