body {
  margin: 0;
  font-family: "Roboto", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/background-analytics.svg");
  background-size: cover;
  background-position: center;
  background-color: #0a0a0f;
  color: #e0e0e0;
  font-size: 14px;
  line-height: 16px;
}

