.wrapper {
  display: flex;
  flex-direction: column;
  padding: 80px;
  .info-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .info-wrapper {
      display: flex;
      flex-direction: column;
      width: 30%;
      .common-info {
        display: flex;
        background-color: rgba($color: #20222e, $alpha: 0.6);
        border-radius: 10px;
        padding: 20px;
        align-items: center;
        .icon-wrapper {
          width: 50px;
          height: 50px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .name-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 10px;
          .name {
            font-size: 22px;
            line-height: 16px;
          }
          .address-wrapper {
            margin-top: 8px;
            display: flex;
            align-items: center;
            a.token-address {
              color: #4da7fa;
              margin-left: 3px;
              text-decoration: none;
              cursor: pointer;
            }
            .link-icon {
              margin-left: 6px;
              cursor: pointer;
              &:active {
                background-color: rgba(255,255,255,0.5) !important;
                border-radius: 50%; 
              }
            }
          }
        }
      }
      .price-info-block {
        margin-top: 12px;
        background: rgba(35, 36, 36, 0.16);
        border-radius: 10px;
        padding: 20px;
        height: 405px;
        .price-wrapper {
          background: #20222e;
          border-radius: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 16px;
          .price-block {
            display: flex;
            align-items: center;
            .price {
              font-weight: 500;
              font-size: 30px;
              line-height: 35px;
              margin-left: 10px;
            }
          }

          .sub-price {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #828282;
            margin-top: 12px;
          }
        }
        .detail-info-wrapper {
          margin-top: 16px;
          padding: 9px 0;
          background: #20222e;
          border-radius: 20px;
          .row {
            width: calc(100% - 32px);
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            padding: 7px 16px;
            .category {
              font-weight: 500;
            }
          }
        }
      }
    }
    .chart-block {
      width: 68%;
    }
  }
  .trading-info-block {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .toggle {
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        color: #ffffff;
      }
      .filter {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 500;
        img {
          margin-left: 8px;
        }
      }
    }
    .table-wrapper {
      margin-top: 20px;
      width: 100%;
      overflow-x: auto;
      table {
        width: 100%;
        background: rgba($color: #20222e, $alpha: 0.55);
        border-radius: 20px;
        thead {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-align: right;
          color: #6c7a88;
          tr {
            td {
              padding: 20px 8px;
              min-width: fit-content;
              white-space: nowrap;
              border-bottom: 1px solid #353535;
            }
          }
        }
        td {
          padding: 10px 0;
          font-weight: 500;
        }
        .date {
          text-align: left !important;
          color: #ffffff;
          padding-left: 20px;
          width: 15%;
          min-width: 145px;
        }
        .other {
          padding-right: 20px;
        }
        tbody {
          tr {
            &:first-child td {
              padding-top: 20px;
            }
            &:last-child td {
              padding-bottom: 20px;
            }
            td {
              text-align: right;
              padding-right: 8px;
              padding-left: 8px;
            }
            td.buy {
              color: #adffd7;
            }
            td.sell {
              color: #ffafaf;
            }
            a.address {
              color: #3484cd;
              text-decoration: none;
            }
            td.other {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .up {
    color: #3c8279;
  }
  .down {
    color: #b64444;
  }
  .stable {
    color: #ffffff;
  }
}
@media only screen and (max-width: 768px) {
  .wrapper {
    padding: 20px;
    .info-wrapper {
      width: 100% !important;
    }
    .chart-block {
      width: 100% !important;
      margin-top: 16px;
      margin-bottom: 30px;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .wrapper {
    padding: 20px;
    .price {
      word-break: break-all;
    }
  }
}
.bsc-scan-icon {
  height: 16px;
  width: 16px;
}
.pagination {
  display: flex;
  width: 100%;
  justify-content: center;
}
.pageButton {
  cursor: pointer;
  border-radius: 10px;
  background-color: rgba($color: #20222e, $alpha: 0.55);
  color: #6c7a88;
  border: none;
  padding: 10px;
  margin: 10px 5px;
}
.pageButton:hover {
  background-color: rgba($color: #20222e, $alpha: 0.25);
  cursor: pointer;
}
.percent-change.increase {
  color: #3c8279;
}
.percent-change.decrease {
  color: #b64444;
}
.percent-change.stable {
  color: #ffffff;
}

.Dropdown-root.custom-dropdown {
  cursor: pointer;
  width: 100%;
  .Dropdown-control {
    background-color: #20222e;
    border: 1px solid #20222e;
    border-radius: 7px;
    cursor: pointer;

  }
  .Dropdown-placeholder {
    color: white;
  }

  .Dropdown-arrow {
    border-color: #fff transparent transparent;
  }
  .Dropdown-menu {
    border: 1px solid #20222e;
    background-color: #20222e;
    .Dropdown-option {
      background-color: #20222e;
      border: 1px solid #20222e;
      color: #fff;

    }
    .Dropdown-option.is-selected {
      color: #adffd7;
    }
  }

}
.Dropdown-root.custom-dropdown.is-open {
   .Dropdown-arrow {
    border-color: transparent transparent #fff !important;
    border-width: 0 5px 5px;
  }
}