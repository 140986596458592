:root {
  .Chart {
    height: 500px;
  }
  .hide-tradingview {
    visibility: hidden;
    height: 548px;
  }
  .parent {
    height: 548px;
  }
  .full-screen {
    height: 100vh;
  }
}
